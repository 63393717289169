import React, { useState } from 'react';
import css from './LoginLayout.module.css';
import branding from '@/assets/branding.png';
import logo from '@/assets/goCryptoBlack.svg';
import logoWhite from '@/assets/goCryptoWhite.svg';
import LangPicker from '@/src/components/controls/LangPicker/LangPicker';
import { preloadGlobalResources } from '@/util/authUtils';
import { useTranslation } from '@/util/helper';
import { withRedux } from '@/util/reduxUtils';
import { connect } from 'react-redux';
import ZendeskContext from '@/context/ZendeskContext';
import ZendeskToggler from '@/src/components/Zendesk/ZendeskToggler/ZendeskToggler';
import MDHead from '@/src/components/layout/MDHead/MDHead';

const LoginLayout = ({
    children,
    languages,
    zendeskVisible = false,
    zendeskAuthorized = false
}) => {
    const { i18n, t } = useTranslation();

    return (
        <div className={css.wrapper}>
            <MDHead />
            <ZendeskContext.Provider value={{ isAuthorized: zendeskAuthorized, zendeskVisible }}>
                <div className={css.staticSection}>
                    <div className={css.logoWrapper}>
                        <img src={logo} />
                    </div>
                    <div className={css.imageWrapper}>
                        <img src={branding} />
                    </div>
                </div>
                <div className={css.loginSection}>
                    <div className={css.loginWrapper}>
                        <div className={css.langPickerWrapper}>
                            <div className={css.flexKeeper} />
                            <div className={css.mobileLogoWrapper}>
                                <img src={logoWhite} />
                            </div>
                            <LangPicker
                                className={css.langPicker}
                                selected={
                                    languages &&
                                    languages.find((language) => language.code === i18n.language)
                                }
                                select={(language) => i18n.changeLanguage(language.code)}
                                options={languages}
                                dark
                            />
                        </div>
                        {children}
                    </div>
                </div>
            </ZendeskContext.Provider>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        languages: state.languages
    };
};

export default connect(mapStateToProps)(LoginLayout);
