import React, {useState} from "react";
import css from "./LoginForm.module.css";
import Input from "@/src/components/controls/Input/Input";
import userIcon from "@/assets/colorable/userIcon.svg";
import lockIcon from "@/assets/lockIcon.svg";
import Checkbox from "@/src/components/controls/Checkbox/Checkbox";
import {LargeYellowButton} from "@/src/components/controls/Button/Button";
import passwordVisibleIcon from "@/assets/openEyeIcon.svg"
import passwordHiddenIcon from "@/assets/closedEyeIcon.svg";
import {useTranslation} from "@/util/helper";


export const PasswordToggler = ({onClick, hidden, t, disabled}) => {
    return <div className={css.toggler} onClick={!disabled ? onClick : () => {}}>
        <div className={css.togglerText}>
            {hidden ? t('password.passwordShow') : t('password.passwordHide')}
        </div>
        <div className={css.togglerIcon}>
            {hidden ? <img src={passwordHiddenIcon} /> : <img src={passwordVisibleIcon} />}
        </div>
    </div>
};


function LoginForm(props) {

    const {t} = useTranslation();

    const {rememberMe, setRememberMe, onSubmit, password, username, setPassword, setUsername, errors, setErrors, isLoading} = props;

    const [passwordHidden, setPasswordHidden] = useState(true);

    return <form className={css.form} onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
    }}>
        <div className={css.innerForm}>
            <Input
                className={css.input}
                error={errors.username}
                name={"username"}
                inline={true}
                value={username}
                type={"text"}
                onChange={(e) => setUsername(e.target.value)}
                prefix={<img src={userIcon}/>}
                placeholder={t('login:username')}
            />
            <Input
                className={css.input}
                type={passwordHidden ? "password" : "text"}
                error={errors.password}
                value={password}
                inline={true}
                name={"password"}
                suffix={<PasswordToggler t={t} hidden={passwordHidden} onClick={() => setPasswordHidden(!passwordHidden)} />}
                onChange={(e) => setPassword(e.target.value)}
                prefix={<img src={lockIcon}/>}
                placeholder={t('login:password')}
            />
            <Checkbox
                className={css.checkbox}
                checked={rememberMe}
                name={'remember_me'}
                onClick={() => setRememberMe(!rememberMe)}
            >
                {t('login:rememberMe')}
            </Checkbox>
        </div>
        <LargeYellowButton isLoading={isLoading} borderless={true} color={"yellow"} className={css.button}>
            {t('login:logIn')}
        </LargeYellowButton>
    </form>
}


export default LoginForm;
